@import url("https://fonts.googleapis.com/css2?family=Rubik:wght@300;500;700&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Comfortaa:wght@300;400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=BenchNine:wght@300;700&display=swap');

.next-steps .fa-link {
  margin-right: 5px;
}

/* Fix for use only flexbox in content area */
.next-steps .row {
  margin-bottom: 0;
}
.minus-bot {
  margin-bottom: 12px !important;
  font-size:12px !important;
}
.next-steps .col-md-5 {
  margin-bottom: 3rem;
}
.ico {
  padding-right: 4px;
}
@media (max-width: 768px) {
  .next-steps .col-md-5 {
    margin-bottom: 0;
  }
}
.ranky {
  display: flex;
  align-items: center;
  justify-content: center;
}
.sranky {
  display: flex;
  align-items: center;
  /* justify-content: center; */
}
.pranky {
  display: flex;
  align-items: center;
  justify-content: right;
}
.leftalign {
  text-align: left;
}
.smalltd {
  padding-right: 0px !important;
  padding-left: 34px !important;
}
.spinner {
  position: absolute;
  display: flex;
  justify-content: center;
  height: 100vh;
  width: 100vw;
  background-color: black;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.result-block-container .result-block {
  opacity: 1;
}
.center-that-but {
  display: flex;
  justify-content: center;
  padding-bottom: 20px;
}
.center-that-buttt {
  display: flex;
  justify-content: center;
  padding-bottom: 5px;
}
.check-btn {
  background-color: green;
  color: white;
  padding: 10px 4px;
  text-align: center;
  border-radius: 10px;
  width: 95%;
}
.leader-table {
    border-collapse: collapse;
    width: 100%;
}
.Toastify__close-button {
  display: none;
}
.leader-table td {
  /* text-align: center; */
  padding: 20px 15px;
}
.leader-table tbody tr  {
  text-align: center;
  padding: 8px;
  /* background-color: #F5F5F5; */
  background-color: #000;
  color: white;
}
.leader-table tbody th  {
  text-align: center;
  padding: 8px;
  background-color: #fff;
}
.leader-table thead tr{
  text-align: center;
  padding: 8px;
  background-image: linear-gradient(rgba(102,102,102,1), rgba(17,17,17,1));
  color: white;
}
.leader-table  tr:nth-child(even) {background-color: #000 !important;}
.leader-div {
  display: flex;
}
.light-date {
  font-weight: 200;
    font-size: 14px;
}
.pad-container {
  padding: 10px 20px;
}
.profile-containers .pad-container {
  /* font-family: 'Rubik'; */
  font-weight: 600;
  text-align: center;
  color: #fff;
  background-color: rgba(1,1,1,.5);
  font-size: 22px;
}
hr {
  color: white;
  background-color: white;
  width: 100%;
  margin-bottom: 0px;
  margin-top: 25px;
}
.edit-button {
  color: #ff0;
  font-weight: 600;
  padding-top: 0;
  cursor: pointer;
  font-size: 14px;
  padding-top: 3px;
}
.thankyouheader {
  padding-bottom: 15px;
  font-size: 20px;
  color: lightgreen;
  padding-top: 7px;
  line-height: 30px;
}
button {
  outline: none !important;
  border-radius: 40px;
  font-family: 'Bebas Neue', sans-serif;
  font-style:italic;
  box-shadow: #000;

}
.thankyoumsg {
  font-size: 14px;
  padding-right: 10px;
  padding-left: 10px;
}
.pad-top {
  margin-top: 28px !important;
}
.profile-header {
  margin-bottom: 10px !important;
  width: 95%;
  margin-top: 16px;
  margin-left: 2.5%;
  color: #2a413a;
  border-radius: 15px;
  /* box-shadow: 5px 2px rgb(0 0 0 / 30%); */
  /* background-image: linear-gradient(rgba(102,102,102,1), rgba(17,17,17,1)); */
}

.grv {
  width: 90px;
  height: 90px;
}
.hero, .shero {
  margin-top: 0px !important;
}
.gold {
  color: #aa6c39;
  z-index: 9;
}
.silver {
  color: #71706E;
  z-index: 9;
}
.container {
  margin-top: 30px !important;
  padding: 0px;
}
.topstuff {
  margin-top: 0px !important;
}
.bronze {
  color: #804a00;
  z-index: 9;
}

.levynum {
  margin-right: 16px;
}
.section-container {
  margin-top: 40px;
}

.form-holder input {
  height: 58px;
  border-radius: 8px;
  font-size: 26px;
  padding-left: 10px;
  padding-bottom: 6px;
}
.form-holder {
  display: flex;
  flex-direction: column;
  padding: 32px 2px;
  margin-bottom: 30px;
}
.form-holder-dog {
  display: flex;
  flex-direction: column;
  padding: 13px 0px;
  margin-bottom: 30px;
  margin-bottom: 10px;
}
.rew-rew {
  display: flex;
  justify-content: center;
  font-size: 19px;
  font-weight: 700;
}
.closebtn:focus{
  outline: none;
}
.linker {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 22px;
  margin-top: 20px;
}
/* .closebtn {
  outline: 0px;
  position: absolute;
  top: 9px;
  right: 0px;
  height: 60px;
  width: 60px;
  font-size: 31px;
  font-weight: 400;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 0px;
  background: transparent;
} */
.hrefcheck {
  width: 100%;
}
.social-share {
  width: 100%;
  border-radius:40px;
}
.closebtn {
  color: #efefef;
}
.twitty {
  padding-left: 4px;
}
.social-share .share-twitty {
  background-color: #1DA1F2 !important;
  background: inherit;
}

.stat-holders {
  display: flex;
  justify-content: center;
  color: #fff;
}
.refix .stat-holders {
  color: #6eee;
}
.refix .badge-label {
  color: #888;
}
.center {
  text-align: center;
}
.bluebut {
  background-color: #0000FF !important;
  width: 90% !important;
}
.rewardsicon {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  border: 1px solid #bebebe;
  padding: 0px;
  margin: 0px;
  background-repeat: no-repeat;
  background-size: cover;
}
.pad-ex {
  padding-left: 12px;
}
.leader-reward {
  font-size: 14px;
}
.level-circ {
  border: 0px solid yellow;
  border-radius: 50%;
  width: 124px;
  height: 124px;
  margin-left: -14px;
  display: flex;
  justify-content: center;
  align-items: center;
  /* font-family: 'Rubik'; */
  font-size: 24px;
  font-weight: 700;
  background: radial-gradient(circle, rgba(246,246,210,1) 35%, lightyellow 48%, rgba(255,246,196,1) 64%);
  color: #111;
  margin-top: 10px;
  display: flex;
  flex-direction: column;
}
.rew-name {
  font-size: 20px;
  padding-top: 10px;
  display: flex;
  justify-content: center;
}
.abby-b {
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  font-size: 15px !important;
  color: lightblue;
}
.close-numy {
  background: linear-gradient(0deg, #091833 0%, #133e7c);
}
.now-level {
  font-size: 14px;
  padding-top: 0px;
  padding-bottom: 0px;
  margin-bottom: 0px;
  color: #111;
}

.five {
  background-image: url("assets/5.png") !important;
  background-size: cover !important;
}
.numy {
  font-size: 54px;
  padding-top: 0px;
  margin-top: 0px;
  line-height: 50px;
}
.grats {
  /* font-family: 'Luckiest Guy'; */
  font-size: 34px;
  padding-bottom: 0px;
  margin-bottom: 0px;
  margin-top: 0px;
  color: #ff0;
}
.lilcoin {
  background-image: url("assets/lilcoin.jpg") !important;
  background-size: cover !important;
}
.extra-pad {
  /* padding-bottom: 24px; */
  padding-bottom: 14px;
}
.itally {
  font-style: italic;
}
.subtitle-change {
  text-align: center;
  font-weight: bold;
  color: yellow;
  font-size: 20px;
}
.title-change {
  text-align: center;
    font-size: 20px;
    padding-top: 11px;
    padding-bottom: 1px;
}
.p-tag {
  margin-top: 8px;
  padding: 4px;

  font-size: 20px;
}
.bordera {
  border-right: 1px solid #bebebe;
  border-left: 1px solid #bebebe;
}
/* .backstyle {
  box-sizing: border-box;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  background-color: rgba(0, 0, 0, 0.7);
  position: fixed;
  inset: 0px;
  z-index: 999;
  perspective: 800px;
  transition: opacity 250ms ease-in-out 0s;
  opacity: 1;
 } */
.hidden {
  display: none;
}
.navbar-toggler {
  z-index: 999999999 !important;
}



.navbar {
  padding: 2px 0px;
}
.nav-item {
  margin-bottom: 1rem;
  height: 40px;
  display: flex;
  align-items: center;
  margin: 9px 0;
  border-bottom: 1px solid;
}
.nav-item a {
  color: white;
}

.shange {
  padding-bottom: 12px;
}
.profile-cont {
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  align-items: center;
}
.profile-picture {
  border-radius: 15px !important;
  width: 104px;
  height: 104px;
  border: 3px solid yellow;
  margin: 15px 18px 10px 18px !important;
}
.profile-cont h1 {
  color: #fff;
  margin-bottom: 0;
  font-weight: 700;
  font-size: 26px;
}
.progress-bar-hawk {
  width: 90%;
  margin-bottom: 0px;
  margin-top: 0px;
  padding-top: 15px;
}
.labeler {
  font-size: 18px;
  padding-bottom: 0px;
  margin-bottom: 3px;
  padding-left: 0px;
  padding-right: 0px;
}
.greygoose {
  background-color: #bebebe;
  color: #111;
  margin-top: 20px;
}
.openseabdg {
  /* background-color: #1DA1F2 !important; */
  background: linear-gradient(0deg, #133e7c 0%, #0abdc6) !important;
}
.open-link-holder {
  outline: 0;
  width: 100% !important;
}
.overwrite {
  margin-top: 0px !important;
}
/* .qrcodepage .navbar, .qrcodepage footer {
  display: none;
}
.qrcodepage {
  background-image: none;
  background-color: #111;
} */

/* design changes */
.container {
  margin-top: 7px !important;
  padding: 0px;
}

.leader-item {
  /* background-image: linear-gradient(rgba(102,102,102,1), rgba(17,17,17,1), rgba(17,17,17,1), rgba(102,102,102,1));   margin: 10px 0 10px 20px;
  border-top-left-radius: 10px;   box-shadow: 5px 3px rgb(0 0 0 / 30%);
  background: rgb(0,0,0);
  background: linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(61,61,61,1) 100%);  border-bottom-left-radius: 10px;*/
 
 
  height: 70px;
  display: flex;
  align-items: center;
  color: #ffffff;

}

.active-leader-item-profile {
  /* background-image: linear-gradient(rgba(102,102,102,1), rgba(17,17,17,1), rgba(17,17,17,1), rgba(102,102,102,1)); */
  /* background-image: linear-gradient(#7a04eb, #7a04eb, #7a04eb, #120458); */
  /* background-image: linear-gradient(#0abdc6, #0abdc6, #0abdc6, #133e7c); */
  background: linear-gradient(0deg, #133e7c 0%, #0abdc6);
  margin: 10px 0 10px 20px;
  border-bottom-left-radius: 20px;
  border-top-left-radius: 20px;
  height: 75px;
  display: flex;
  align-items: center;
  color: white;
  box-shadow: 5px 3px rgb(0 0 0 / 30%);
}
.leader-item .gravy {
  border-radius: 15px;
  width: 60px;
  height: 60px;
  border: 3px solid yellow;
  margin: 0 18px;
}

iframe {
  display: none;
}
.leader-name {
  /* font-family: 'Rubik'; */
  /* font-family: 'Figtree', sans-serif; */
  font-family: 'Bebas Neue', sans-serif;
  font-size: 40px;
  font-weight: 700;
  display: flex;
  align-items: left;
  flex: 1;
}
/* .leader-name::before {
  content: '-';
  margin-right: 7px;
  font-size: 20px;
} */
footer {
  min-height: initial;
}

.leader-level {
  font-size: 14px;
  font-weight: 400;
  padding-left: 0px;
  align-items: center;
  padding-left: 10px;
  margin-right: 20px;
  width: 100%;
}
.flex-level {
  flex: 1;
}
.active-leader-item .leader-name {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex: 1;
}
.active-leader-item-profile .leader-name {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex: 1;
}
.active-leader-item .leader-name::before {
  content: none;
}
.active-leader-item-profile .leader-name::before {
  content: none;
}
.active-leader-item .leader-level {
  padding-left: 0px;
}
.active-leader-item-profile .leader-level {
  padding-left: 0px;
}
.container-lead:first-child .active-leader-item {
  background: rgb(207,178,23);
  background: linear-gradient(0deg, #ffc703 0%, #ff4200 100%);
  /* background: linear-gradient(0deg, hsla(45, 99%, 33%, 1) 0%, hsla(46, 61%, 52%, 1) 100%); */
}
.container-lead:nth-child(2)  .active-leader-item {
  background: linear-gradient(0deg, #10e4d6 0%, #06805b 100%);
  /* background: linear-gradient(360deg, rgba(111,107,103,1) 27%, rgba(77,75,74,1) 68%, rgba(188,185,182,1) 100%); */
  /* background: linear-gradient(0deg, rgb(192, 192, 192, 1) 0%, rgba(	179, 179, 179,1) 100%); */
}
.container-lead:nth-child(3)  .active-leader-item {
  background: rgb(205,127,50);
  background: linear-gradient(0deg, #00c0ef 0%, #1064ff 100%);
/* background: linear-gradient(360deg, rgba(205,127,50,1) 54%, rgba(205,171,138,1) 100%); */
}
.container-lead:nth-child(even) .active-leader-item-profile {
  background: #091833;
  /* background: linear-gradient(0deg, #133e7c 0%, #0abdc6); */
  background: linear-gradient(0deg, #091833 0%, #133e7c);
  
}
.leader-suffix {
  font-size: 12px;
  margin-top: -8px;
}
.active-leader-item .leader-suffix {
  font-size: 17px;
  margin-top: -8px;
}
.active-leader-item-profile .leader-suffix {
  font-size: 17px;
  margin-top: -8px;
}
.navbar-toggler {
  margin-right: 12px !important;
}
.container {
  margin-top: 0px !important;
}
.navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255,255,255, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E") !important;
}

.navbar-toggler {
  border: none;
} 
.navbar-toggler-icon {
  display: inline-block;
  width: 30px;
  height: 30px;
}
.svg-inline--fa.fa-w-12 {
  width: 43px;
  margin-right: 12px;
  height: 38px;
}
h1{
  text-shadow: 2px 2px 5px black;
}

/* profile changes */
.leader-h1 {
  position: absolute;
  top: 28px;
  right: 37%;
  color: #d4cac8;
  font-size: 22px;
  z-index: 9999;
  text-transform: uppercase;
  font-weight: 700;
}
.reward-item {
  /* background: linear-gradient(0deg, rgba(205,127,50, 1) 0%, rgba(205,141,110, 1) 100%) !important; */
}
.navbar-nav {
  /* background-color: #2A52376f52376f413A; */
  z-index: 99;
  /* margin-top: 0px !important; */
  margin-top: 0px !important;
  /* border-bottom-right-radius: 25px;
  border-bottom-left-radius: 25px; */
  color: white;
  margin-left: 0px;
  padding-left: 24px;
  padding-right: 24px;
}
.navbar>.container {
  position: absolute;
  top: 20px;
}
.navbar>.container, .navbar>.container-fluid {
  z-index: 99;
}
.leadery .leader-level {
  width: auto;
}
.backersty .refix {
  color: white;
  background-image: linear-gradient(rgba(102,102,102,1), rgba(17,17,17,1));
}
.btn{
  width: 100% !important;
  border-radius:30px !important;
  border:1px solid purple !important;
  font-style:italic;
  font-size:30px;
  font-family:'Bebas Neue', sans-serif;
  background-image:linear-gradient( #423785 , #a855bb);
}
.navbar-nav .btn {
  font-size: 16px;
    font-weight: 600;
    text-transform: uppercase;
    line-height: 1.3em;
    fill: #B39F8F;
    color: #ffffff;
    background-color: white;
    border-radius: 0px 0px 0px 0px;
    padding: 10px 30px 10px 30px;
}
.navbar-collapse {
  /* border-top: 1px solid white; */
  background: #000000;
  border-radius:0px 0px 40px 40px;
  margin:0px 10px 0px 10px;
}
@media (max-width: 767px){
.navbar-nav:last-child {
    margin-top: 0px !important;
    border-bottom-left-radius: 14px;
    border-bottom-right-radius: 14px;
}

}

@media (min-width: 768px) {
  .navbar-expand-md .navbar-collapse {
      display: -ms-flexbox !important;
      display: flex !important;
      -ms-flex-preferred-size: auto;
      flex-basis: auto;
      margin-top: -5px;
  }
}
