@import url('https://fonts.googleapis.com/css2?family=Fredoka+One&display=swap');

.spinner-container {
    text-align: center;
    margin-top: 80px;
  }
  
  .wheel-container {
    width: 300px;
    height: 300px;
    margin: 0 auto;
    position: relative;
  }
  
  .wheel {
    width: 100%;
    height: 100%;
    border-radius: 50%; /* Circle shape */
    position: absolute;
    clip-path: circle(50%); /* Clip to circle */
  }
  
  .slice {
    position: absolute;
    width: 50%; /* Half the width of the circle */
    height: 50%; /* Half the height of the circle */
    transform-origin: 100% 100%; /* Rotation around the center of each slice */
  }
  
  .slice span {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(0deg); /* Adjust this for correct text alignment */
    transform-origin: center;
    font-size: 12px;
    font-weight: 500;
    color: white;
    text-align: center;
    width: 80%; /* Limit width of the text inside slice */
    font-family: 'Fredoka One', sans-serif; /* Add this line */
}
  
  .spin-lu {
    height: 130px;
    float: right;
    margin-top: -21px;
    padding-right: 4px;
  }
  
  button {
    margin-top: 20px;
    padding: 10px 20px;
    background-color: #f39c12;
    color: white;
    font-size: 16px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  button:disabled {
    background-color: #bdc3c7;
    cursor: not-allowed;
  }
  
  .result {
    margin-top: 40px;
    font-size: 18px;
    font-weight: bold;
    color: #2c3e50;
  }

  .slice:nth-child(1) {
      background: none !important;
      background-color: #C87B7C !important;
}
.slice:nth-child(2) {
    background: none !important;
    background-color: #1A2441 !important;
}
.slice:nth-child(3) {
    background: none !important;
    background-color: #1B6145 !important;
}
.slice:nth-child(4) {
    background: none !important;
    background-color: #697B30 !important;
}
.slice:nth-child(5) {
    background: none !important;
    background-color: #C6E1F1 !important;
}
.slice:nth-child(6) {
    background: none !important;
    background-color: #CDA2E0 !important;
}




@media (max-width: 768px) {
    /* .spin-lu {
        height: 140px;
        position: absolute;
        z-index: 99999;
        top: 17px;
        right: 108px;
    } */
    .wheel {
        margin-top: 30px;
    }
    .spinner-container {
        margin-bottom: 50px;
        position: relative;
    }
    .spinner-container button {
        position: absolute;
        z-index: 100000;
        top: 114px;
        right: 140px;
        height: 86px;
        width: 86px;
        border-radius: 60%;
        /* border: 2px solid #52376f; */
        background-image: url('./assets/sping-bgd.png');
        background-size: cover;
        background-position: center;
        box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.3); /* Adds a soft shadow */
    }
  }

  .spinner-container {
    text-align: center;
    margin-top: 50px;
    position: relative;
  }
  
  .wheel-container {
    position: relative;
    width: 300px;
    height: 300px;
    margin: 0 auto;
  }
  
  .custom-marker {
    position: absolute;
    width: 40px; /* Adjust size as needed */
    height: 40px;
    top: 50%; /* Center vertically */
    left: 50%; /* Center horizontally */
    transform: translate(-50%, -150%); /* Adjust offset to place above the wheel */
    z-index: 1000; /* Ensure it is above the wheel */
  }
  