
.game-container { 
  position: relative;
  width: 240px;
  height: 208px;
  background: url(./assets/images/map.png) no-repeat no-repeat;
  transform: scale(3);
  image-rendering: pixelated;
}
/* .it .Character_sprite {
  border: 2px dotted red;
  border-radius: 25px;
  padding: 8px;
  animation: pulse 1s infinite;
  box-shadow: 0 0 10px red;
  background-color: rgba(255, 0, 0, 0.2);
} */
/* Style for the character sprite */
.it .Character_sprite {
  position: relative; /* Required for positioning the pseudo-element */
  border-radius: 25px;
  padding: 8px;
  /* overflow: hidden;  */
  overflow: initial;
  /* Applying pulse animation */
  animation: pulse 2s ease-in-out infinite;
}

/* Pseudo-element for the circular dotted border */
.it .Character_sprite::before {
  content: '';
    position: absolute;
    top: -2px;
    left: -3px;
    width: calc(100% + 5px);
    height: calc(100% + 5px);
    border-radius: 50%;
    border: 2px dotted red;
    box-sizing: border-box;
    animation: rotateBorder 4s linear infinite;
    pointer-events: none;
}
.player-info {
  position: absolute;
  top: 0;
  left:0;
  padding: 1em;
  display: flex;
  gap: 0.5em;
  align-items: flex-end
}

label {
  display: block;
  font-weight: bold;
}

/* input[type="text"],
button {
  font-family: inherit;
  font-weight: bold;
  font-size: 18px;
  height: 44px;
  border-radius: 4px;
  outline: 0;
} */
@keyframes rotateBorder {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes colorCycle {
  0% { border-color: red; }
  25% { border-color: orange; }
  50% { border-color: yellow; }
  75% { border-color: green; }
  100% { border-color: blue; }
}

@keyframes pulse {
  0% {
    transform: scale(1);
    opacity: 1;
  }
  50% {
    transform: scale(1.1);
    opacity: 0.7;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}
input[type="text"] {
  outline: 0;
  padding-left: 0.5em;
  border: 3px solid #222034;
  width: 150px;
  /* text-transform: uppercase; */
}
/* input[type="text"]:focus {
  border-color: #f000ff;
} */

/* button {
  padding-left: 0.5em;
  padding-right: 0.5em;
  background: #59ff5a;
  border: 0;
  border-bottom: 2px solid #1e830b;
  cursor: pointer;
}
button:active {
  position: relative;
  top: 1px;
} */

/* Characters */
.grid-cell {
  position: absolute;
  width: 16px;
  height: 16px;
}
.Character {
  transition: transform 0.4s;
}
.Character.you {
  z-index: 1;
}
.Character.you .Character_you-arrow {
  display: block;
}
.Character_you-arrow {
  display: none;
  position: absolute;
  top:-18px;
  left: 5px;
  width: 7px;
  height: 5px;
  background: url(./assets/images/arrow.png) no-repeat no-repeat;
}
.Character_sprite {
  overflow: hidden;
  top: -3px;
  background: url(./assets/images/characters.png);
}
.Character[data-direction="right"] .Character_sprite {
  background-position-x: 16px;
}
.Character[data-color="red"] .Character_sprite {
  background-position-y: -16px;
}
.Character[data-color="orange"] .Character_sprite {
  background-position-y: -32px;
}
.Character[data-color="yellow"] .Character_sprite {
  background-position-y: -48px;
}
.Character[data-color="green"] .Character_sprite {
  background-position-y: -64px;
}
.Character[data-color="purple"] .Character_sprite {
  background-position-y: -80px;
}
.Character[data-color="eggplant"] .Character_sprite {
  background-position-y: -96px;
}
.Character[data-color="fox"] .Character_sprite {
  background-position-y: -112px;
}
.Character[data-color="blob"] .Character_sprite {
  background-position-y: -128px;
}
.Character[data-color="knight"] .Character_sprite {
  background-position-y: -144px;
}
.Character[data-color="alien"] .Character_sprite {
  background-position-y: -160px;
}
.Character_shadow {
  background: url(./assets/images/shadow.png) no-repeat no-repeat;
}

.Character_name-container {
  position: absolute;
  top: -12px;
  left: -5px;
  font-size: 5px;
  padding: 1px 2px 1px;
  border-radius: 3px;
  background: #333;
  color: white;
  font-weight: bold;
  text-transform: uppercase;
  white-space: nowrap;
}
.Character_coins {
  margin-left: 1px;
  color: gold;
}

/* Coins */
.Coin_sprite {
  background: url(./assets/images/coin.png) no-repeat no-repeat;
  animation: coinFloat 0.8s linear infinite alternate-reverse;
}
@keyframes coinFloat {
  from { transform: translateY(0) }
  to { transform: translateY(5px) }
}
.Coin_shadow {
  background: url(./assets/images/coin-shadow.png) no-repeat no-repeat;
}

.Character.tagged {
  border: 2px solid red;
  animation: shake 0.5s infinite;
}

@keyframes shake {
  0% { transform: translate(1px, 1px) rotate(0deg); }
  10% { transform: translate(-1px, -2px) rotate(-1deg); }
  20% { transform: translate(-3px, 0px) rotate(1deg); }
  30% { transform: translate(3px, 2px) rotate(0deg); }
  40% { transform: translate(1px, -1px)}
  }

  #tagged-message {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(0deg); /* Added rotate */
    z-index: 1000;
    font-size: 4rem;
    font-weight: bold;
    color: white;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.8);
    padding: 20px;
    /* background: rgba(255, 0, 0, 0.8); */
    border-radius: 10px;
    opacity: 0;
    transition: opacity 0.5s ease-in-out;
    pointer-events: none;
  }
  
  
  .hidden {
    display: none;
  }
  .main-game {
    margin: 0;
  padding: 0;
  height: 100%;
  background: linear-gradient(180deg, #64BEFF -50%, #6e96ff 69.71%, #2a65ff 150%);
  font-family: 'Source Sans Pro', sans-serif;
  display: flex;
  align-items: center;
  justify-content: center;
  }