@import url("https://fonts.googleapis.com/css2?family=Rubik:wght@300;500;700&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Comfortaa:wght@300;400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=BenchNine:wght@300;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Luckiest+Guy&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Comfortaa:wght@300;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=VT323&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500&display=swap');

body{
  background-color: #000;
  font-family: 'Bebas Neue', sans-serif;
}

.next-steps .fa-link {
  margin-right: 5px;
}

/* Fix for use only flexbox in content area */
.next-steps .row {
  margin-bottom: 0;
}
.minus-bot {
  margin-bottom: 12px !important;
  font-size:12px !important;
}
.next-steps .col-md-5 {
  margin-bottom: 3rem;
}
.ico {
  padding-right: 4px;
}
.membersince {
  font-size: 10px;
}
@media (max-width: 768px) {
  .next-steps .col-md-5 {
    margin-bottom: 0;
  }
}
.ranky {
  display: flex;
  align-items: center;
  justify-content: center;
}
.sranky {
  display: flex;
  align-items: center;
  /* justify-content: center; */
}
.pranky {
  display: flex;
  align-items: center;
  justify-content: right;
}
.leftalign {
  text-align: left;
}
.star {
  width: 96% !important;
  height: 98% !important;
  top: 2% !important;
  z-index: 999999999 !important;
}
.smalltd {
  padding-right: 0px !important;
  padding-left: 34px !important;
}
.btnlead {
  cursor: pointer;
}
.spinner {
  position: absolute;
  display: flex;
  justify-content: center;
  height: 100vh;
  width: 100vw;
  background-color: white;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.result-block-container .result-block {
  opacity: 1;
}
.center-that-but {
  display: flex;
  justify-content: center;
  padding-bottom: 20px;

}
.changer {
  font-size: 24px;
}
.st-padding {
  padding-top: 13px;
}
.check-btn {
  background-color: green;
  color: white;
  padding: 10px 4px;
  text-align: center;
  width: 95%;
}
.leader-table {
    border-collapse: collapse;
    width: 100%;
}
.Toastify__close-button {
  display: none;
}
.leader-table td {
  /* text-align: center; */
  padding: 20px 15px;
}
.leader-table tbody tr  {
  text-align: center;
  padding: 8px;
  /* background-color: #F5F5F5; */
  background-color: #000;
  color: white;
}
.leader-table tbody th  {
  text-align: center;
  padding: 8px;
  background-color: #fff;
}
.leader-table thead tr{
  text-align: center;
  padding: 8px;
  background-color: #000;
  color: white;
}
.leader-table  tr:nth-child(even) {background-color: #000 !important;}
.leader-div {
  display: flex;
}
.leadery {
  padding-top: 12px;
}
.leader-contain {
  /* margin-bottom: 20px; */
  /* because I feel like it!
  background-color: #0f0f0f;
    box-shadow: -4px 6px 7px #666;
*/
/* background-image: linear-gradient(rgba(102,102,102,1), rgba(17,17,17,1)); */
  /* background: #000; */
  /* background-image: url(../src/assets/birdie.png); */

  background-size: cover;
    border: 0 solid #fff;
    width: 94%;
    margin-left: 3%;
    margin-top: 14px;
    padding-top: 8px;
    margin-bottom: 20px;
}


.leader-contain.chng {
  padding-top: 0px;
  margin-top: 0px;
}
.badge-container {
  display: flex;
  border-radius:40px;
  background-color:#fff;
  flex-direction: column;
}
.container-lead {
 /* height: 94px;*/
}
.minus {
  position: absolute;
    top: -47px;
    left: 1px;
}
.minus .final-ava {
  width: 98px;
    height: 98px;
    margin-top: 21px;
    margin-left: 14px;
}
.badge-label {
  font-size: 25px;
  font-family:'Bebas Neue', sans-serif;
  margin: 0 20px;
  padding: 9px 0px;
  font-weight: 400;
  color: #666;
}
.badges {
  display: flex;
}
.badge-container .badges {
  display: flex;
  flex-wrap: wrap;
}
.badge-container .badges .badge {
  /* width: 120px;
  display: flex;
  justify-content: center;
  padding: 6px 0; */

  width: 100px;
  display: flex;
  justify-content: center;
  padding: 6px 0;
}
.childtarsky {
  display: flex;
  flex-direction: column;
  padding: 12px 13px;
  text-align: center;
}
.claimed {
  border: 1px dotted #9f5965;
  color: #9f5965;
  margin-top: 18px;
  padding: 7px 0;
}
.expired {
  border: 1px dotted darkred;
  color: darkred;
  margin-top: 18px;
  padding: 7px 0;
}
.activer {
  border: 1px dotted lightgreen;
  margin-top: 18px;
  padding: 7px 0;
}
.claimg {
  /* font-family: 'Figtree', sans-serif;   */
  font-family: 'Nunito', sans-serif;  
  font-size: 48px;
  color: #f8eddd;
  text-transform: uppercase;
}
.claimdeez {
  margin-bottom: 16px;
}
.holdertarsky {
  display: flex;
  flex-direction: column;
  font-weight: 500;
  font-size: 20px;
  padding-top: 12px;
  line-height: 22px;
}
.holdertarsky .lab {
  font-size: 15px;
  font-weight: 400;
}
.imgholdy {
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 10px 0;
}
.light-date {
  font-weight: 200;
    font-size: 14px;
}
.pad-container {
  padding: 10px 20px;
}
.profile-containers .pad-container {
  /* font-family: 'Rubik'; */
    font-weight: 600;
  text-align: center;
  color: #fff;
  background-color: rgba(1,1,1,.5);
  font-size: 22px;
}
hr {
  color: white;
  background-color: white;
  width: 100%;
  margin-bottom: 0px;
  margin-top: 25px;
}
.edit-button {
  color: #ff0;
  font-weight: 600;
  padding-top: 0;
  cursor: pointer;
  font-size: 14px;
  padding-top: 3px;
}
.open-dia {
  background-color: transparent;
  border: 0px;
  outline: none;
  margin: 0 4px;
  position: relative;
}
.open-dia.stia {
  width: 100%;
}
.thankyouheader {
  padding-bottom: 15px;
  font-size: 20px;
  color: lightgreen;
  padding-top: 7px;
  line-height: 30px;
}
button {
  outline: none !important;
}
.thankyoumsg {
  font-size: 14px;
  padding-right: 10px;
  padding-left: 10px;
}
.pad-top {
  margin-top: 28px !important;
}
.profile-header {
  margin-bottom: 10px !important;
  width: 95%;
  margin-top: 16px;
  margin-left: 2.5%;
  color: white;
  /* box-shadow: 5px 2px rgb(0 0 0 / 30%); */
  /* background-color: #000; */
}
.gravy {
  width: 30px;
  height: 30px;
  /* margin-right: 13px; */
}
.grv {
  width: 90px;
  height: 90px;
}
.updates {
  padding-bottom: 0px;
  padding-left: 7px;
  margin-bottom: -4px !important;
  padding-top: 0px;
  margin-left: 26px;
  width: 88px;
}
.hero {
  margin-top: 9px !important;
  margin-bottom: 1px !important;
  max-width: 100%;
}
.shero {
  margin-top: 9px !important;
  margin-bottom: 1px !important;
}
.gold {
  color: #aa6c39;
  z-index: 9;
}
.silver {
  color: #71706E;
  z-index: 9;
}
.container {
  margin-top: 30px !important;
  padding: 0px;
}
.topstuff {
  margin-top: 0px !important;
}
.bronze {
  color: #804a00;
  z-index: 9;
}
.level-lev {
  padding-right: 4px;
  padding-right: 4px;
  /* color: #4d4d4d; */
  /* color: #ffffff; */
  font-weight: 700;
  /* color: yellow; */
  color: #ffcc00;
}
.levynum {
  margin-right: 16px;
}
.section-container {
  margin-top: 40px;
}
.smtitle {
 
  line-height: 46px;
  font-size: 38px;

}
.rewards-description {
  padding-top: 14px;
    font-size: 20px;
    color: #ffffff;
    font-weight: 400;
    line-height: 20px;
}
.bigtitle {
  font-size: 34px;
  line-height: 28px;
  padding-top: 2px;
  text-transform: capitalize;
}
.refix {
  box-sizing: border-box;
  border-radius: 40px;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  text-align: center;
  background-image: linear-gradient(#0bbcd5, #0666a5);
  border: 0px solid #f8eddd;
  color: #ffffff;
  position: fixed; /* Keep fixed positioning for modal */
  top: 2%; /* Adjust for visibility */
  left: 50%;
  transform: translateX(-50%);
  padding: 1em;
  max-height: 90vh;
  outline: 0;
  z-index: 120;
  width: 96%;
  overflow-y: auto;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;
}

.refix .level-lev {
  color: white !important;
}
.stuff {
  flex: 1 1;
  text-align: left;
  text-transform: capitalize;
  /* font-family: 'Figtree', sans-serif; */
  font-family: 'Bebas Neue', sans-serif;
  line-height: 27px;
  font-style: italic;
}

.form-holder input {
  height: 58px;
  font-size: 26px;
  padding-left: 10px;
  padding-bottom: 6px;
}
.form-holder {
  display: flex;
  flex-direction: column;
  padding: 32px 2px;
  margin-bottom: 30px;
}
.logoholder  {
  display: flex;
  flex-direction: column;
  white-space: nowrap;
}
.sublogo {
  color: #fff;
    font-family: 'Montserrat', sans-serif;
    font-size: 9px;
    font-weight: 700;
    letter-spacing: 1.5px;
    text-transform: uppercase;
    padding-left: 18px;
    padding-top: 4px;
    padding-bottom: 7px;
}
.bblogo {
  color: #fff;
  font-family: 'Montserrat', sans-serif;
  font-size: 22px;
  font-weight: 700;
  letter-spacing: 3.5px;
  text-transform: uppercase;
  line-height: 1.2;
  padding-left: 18px;
}
.form-holder-dog {
  display: flex;
  flex-direction: column;
  padding: 13px 0px;
  margin-bottom: 30px;
  margin-bottom: 10px;
}
.rew-rew {
  display: flex;
  justify-content: center;
  font-size: 19px;
  font-weight: 700;
}
.closebtn:focus{
  outline: none;
}
.linker {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 22px;
  margin-top: 20px;
}
.closebtn {
  outline: 0px;
  position: absolute;
  top: -10px;
  right: 9px;
    height: 50px;
    width: 50px;
    font-size: 40px;
    font-weight: 400;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 0px;
    background: transparent;
}
.zero-top {
  padding-top: 0px;
}
.closebtn svg {
  color: #ffffff;

}
.hrefcheck {
  width: 100%;
}
.nameheader {
  margin-bottom: 12px;
  text-transform: capitalize;
  font-family: "Bebas Neue", sans-serif;
  font-size: 50px;
  color: #fff;
}
.userstuff h1 {
font-family: "Bebas Neue", sans-serif;
  font-size: 50px;
  color: #fff;
}
.social-share {
  width: 100%;
  margin-top: 14px !important;
}
.closebtn {
  color: #efefef;
  background-color: red;
  border-radius:40px;
}
.partlink {
  color: #1DA1F2 !important;
  padding-left: 6px;
}
.twitty {
  padding-left: 4px;
}
.social-share .share-twitty {
  background-color: #1DA1F2 !important;
  background: inherit;
  border-radius:40px;
}
.clicklink {
  margin-bottom: 10px;
  margin-top: 0px;
  padding: 10px 23px;
  font-size: 18px;
  background-color: green;
  border: 0px;
  color: white;
  width: 100%;
  margin: 0px 5px;
}
.stat-holders {
  display: flex;
  background-image:linear-gradient(#222222,#363636);
  color:#fff;
  border-radius:40px;
  justify-content: center;
}
.center {
  text-align: center;
}
.bluebut {
  background-color: #0000FF !important;
  width: 90% !important;
}
.rewardsicon {
  width: 50px;
  height: 50px;
  border: 1px solid #ffffff;
  padding: 0px;
  margin: 0px;
  background-repeat: no-repeat;
  background-size: cover;
}
.starty {
  margin-top: 24px;
  position: relative;
}
.overwrite h1 {
  color: #ffffff;
  background-color: #000;
  margin-top:-28px;
  z-index: 999;
  font-weight: 400;
  padding-bottom: 0;
  margin-bottom: 0!important;
  /* font-family: 'Figtree', sans-serif; */
  font-family: 'Bebas Neue', sans-serif;
  font-size: 50px;
  font-style: italic;
  font-weight: 700;
  text-align: center;
  text-transform: capitalize;
  padding-top: 26px;
  background: transparent;
}
.contenty {
  margin-bottom: 40px;
  font-size: 19px;
}
.user-inf-new {
  text-align: center;
}
.cherry {
  background-image: url("assets/tacoface.png") !important;
  background-size: cover !important;
}
.wallet {
  background-image: url("assets/wallet.png") !important;
  background-size: cover !important;
}
.leveltworeward {
  background-image: url("assets/leveltworeward.png") !important;
  background-size: cover !important;
}
.levelthreereward {
  background-image: url("assets/levelthreereward.png") !important;
  background-size: cover !important;
}
.levelfourreward {
  background-image: url("assets/levelfourreward.png") !important;
  background-size: cover !important;
}
.levelfivereward {
  background-image: url("assets/levelfivereward.png") !important;
  background-size: cover !important;
}
.levelsixreward {
  background-image: url("assets/levelsixreward.png") !important;
  background-size: cover !important;
}
.levelsevenreward {
  background-image: url("assets/levelsevenreward.png") !important;
  background-size: cover !important;
}
.leveleightreward {
  background-image: url("assets/leveleightreward.png") !important;
  background-size: cover !important;
}
.levelninereward {
  background-image: url("assets/levelninereward.png") !important;
  background-size: cover !important;
}
.leveltenreward {
  background-image: url("assets/leveltenreward.png") !important;
  background-size: cover !important;
}
.cupcakes {
  background-image: url("assets/cupcake.png") !important;
  background-size: cover !important;
}
.milkshakes {
  background-image: url("assets/ragrets.png") !important;
  background-size: cover !important;
}
.points {
  background-image: url("assets/fren.png") !important;
  background-size: cover !important;
}
.nfd {
  background-image: url("assets/nfd.png") !important;
  background-size: cover !important;
}
.nfd3 {
  background-image: url("assets/nfd3.png") !important;
  background-size: cover !important;
}
.nfd7 {
  background-image: url("assets/nfd7.png") !important;
  background-size: cover !important;
}
.nfd20 {
  background-image: url("assets/nfd20.png") !important;
  background-size: cover !important;
}
.nfd21 {
  background-image: url("assets/nfd21.png") !important;
  background-size: cover !important;
}
.pointpro {
  font-size: 100px;
  /* font-family: 'Figtree', sans-serif;
   */
   font-family: 'Bebas Neue', sans-serif;
   font-style:italic;
  font-weight: 600;
  line-height: 40px;
  color: #ffcc00;
  background-image: linear-gradient(rgba(102,102,102,1), rgba(17,17,17,1));
  width: 100%;
  height: 150px;
  margin-left:0px;
  border-radius:40px;
  padding-top: 40px;
}
.pointpro .lab {
  font-size: 30px
}
.pad-ex {
  padding-left: 12px;
}
.leader-reward {
  font-size: 14px;
}
.level-circ {
  border: 0px solid yellow;
  width: 124px;
  height: 124px;
  margin-left: -14px;
  display: flex;
  justify-content: center;
  align-items: center;
  /* font-family: 'Rubik'; */
  font-size: 24px;
  font-weight: 700;
  background: radial-gradient(circle, rgba(246,246,210,1) 35%, lightyellow 48%, rgba(255,246,196,1) 64%);
  color: #111;
  margin-top: 10px;
  display: flex;
  flex-direction: column;
}
.rew-name {
  font-size: 20px;
  padding-top: 10px;
  display: flex;
  justify-content: center;
}
.abby-b {
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  font-size: 15px !important;
  color: lightblue;
  padding: 6px 0 !important;
}
.close-numy {
  background: linear-gradient(0deg, #091833 0%, #133e7c);
}
.now-level {
  font-size: 14px;
  padding-top: 0px;
  padding-bottom: 0px;
  margin-bottom: 0px;
  color: #111;
}
.leveler-div {
  font-weight: bold;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 30px;
  padding-top: 0px;
}
.five {
  background-image: url("assets/5.png") !important;
  background-size: cover !important;
}
.numy {
  font-size: 54px;
  padding-top: 0px;
  margin-top: 0px;
  line-height: 50px;
}
.grats {
  /* font-family: 'Luckiest Guy'; */
  font-size: 34px;
  padding-bottom: 0px;
  margin-bottom: 0px;
  margin-top: 0px;
  color: #ff0;
}
.lilcoin {
  background-image: url("assets/lilcoin.jpg") !important;
  background-size: cover !important;
}
.extra-pad {
  /* padding-bottom: 24px; */
  padding-bottom: 14px;
}
.itally {
  font-style: italic;
}
.subtitle-change {
  text-align: center;
  font-weight: bold;
  color: yellow;
  font-size: 20px;
}
.title-change {
  text-align: center;
    font-size: 20px;
    padding-top: 11px;
    padding-bottom: 1px;
}
.p-tag {
  margin-top: 8px;
  padding: 4px;
  font-size: 20px;
}
.bordera {
  border-right: 1px solid #ffffff;
  border-left: 1px solid #ffffff;
}
.backstyle {
  box-sizing: border-box;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  background-color: rgba(0, 0, 0, 0.7);
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 999;
  perspective: 800px;
  transition: opacity 250ms ease-in-out;
  opacity: 1;
  overflow: hidden; /* Prevent scrolling outside the modal */
}

.hidden {
  display: none;
}
.h-100 {
  /* background-image: url(../src/assets/backy.png);
   */
  /* background: linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(61,61,61,1) 100%); */
  /* max-width: 100%; */
  background-image: linear-gradient( #423785 , #a855bb);  width: 100%;
  max-width: 100%;
  will-change: position;
  /* height: initial !important; */
}
.navbar-toggler {
  z-index: 99999;
}
footer {
  box-shadow: none;
}
footer.bg-light {
  /* background-color: rgba(255,253,253,1) !important; */
  background: #000000 !important;
  color: #545454;
  min-height: 78px;
  border-top: 0px;
  
}
footer.bg-light p {
  margin-bottom: 0px;
}
footer.bg-light a {
  color: #fff;
    font-weight: 500;
}
.navbar {
  padding: 2px 0px;
}
.nav-item {
  margin-bottom: 1rem;
  height: 30px;
  display: flex;
  align-items: center;
  font-family: 'Bebas Neue', sans-serif;
  font-style: italic;
  font-size: 40px;
  font-weight: 700;
}
.nav-item a {
  color: white;
}
.navbar .router-link-exact-active {
  /* border-bottom: 3px solid yellow; */
  border-bottom: none;
  color: rgb(144, 0, 184) !important;
  /* font-family: 'Rubik'; */
  font-family: 'Bebas Neue', sans-serif;

}
.navbar .router-link-exact-active svg {
  color: rgb(144, 8, 172) !important;
}
.shange {
  padding-bottom: 12px;
}
.profile-cont {
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  align-items: center;
}
.profile-picture {
  border-radius: 15px !important;
  width: 104px;
  height: 104px;
  border: 3px solid yellow;
  /* margin: 15px 18px 10px 18px !important; */
  margin-left: auto !important;
  margin-right: auto !important;
  left: 0;
  right: 0;
}


.profile-picture.shict {
  border-radius: 55% !important;
  position: absolute;
  top: -55px;
  left: 0px;
  border: 0px;
  width: 90px;
  height: 90px;
}
.profile-picture.shict.tt {
  margin-left: 14px !important;
}
.profile-cont h1 {
  color: #fff;
  font-family: 'Figtree', sans-serif;
  margin-bottom: 0;
  font-weight: 700;
  font-size: 26px;
}
.progress-bar-hawk {
  width: 90%;
  margin-bottom: 0px;
  margin-top: 0px;
  padding-top: 15px;
  margin: 0 auto;
}
.minus-bot {
  position: absolute;
  top: 5px;
  left: 18px;
  color: #ffffff;
  font-size: 16px;
  line-height: 14px;
}
.minus-bot.plus {
  right: 18px;
  left: auto;
}
.labeler {
  font-size: 18px;
  padding-bottom: 0px;
  margin-bottom: 3px;
  padding-left: 0px;
  padding-right: 0px;
}
.greygoose {
  background-color: #ffffff;
  color: #111;
  margin-top: 20px;
}
.qr-image {
  width: 100%;
  /* margin-top: 10%; */
}
.openseabdg {
  /* background-color: #1DA1F2 !important; */
  background: linear-gradient(0deg, #133e7c 0%, #0abdc6) !important;
}
.openseabdg.green {
  background: linear-gradient(0deg, darkgreen 0%, green) !important;
}
.white {
  color: white;
  font-size: 18px;
  font-weight: bold;
  padding-bottom: 26px;
}
.progress-line {
  margin-top: 2px;
  font-size: 13px;
  margin-bottom: 24px;
  font-style: italic;
  color: #ffcc00;
}
.dj {
  margin-bottom: 0px;
    padding-bottom: 0px;
}
.no-button {
  border: 0px solid #0abdc6 !important;
  background-color: transparent !important;
  color: #fc0 !important;
  margin-top: 8px;
  font-size: 20px;
}
.open-link-holder {
  outline: 0;
  width: 100% !important;
}
.overwrite {
  margin-top: 0px !important;
  margin-bottom: 0px !important;
}
.qrcodepage .navbar, .qrcodepage footer {
  display: none;
}
/* .qrcodepage {
  background-image: none;
  background-color: rgba(238,133,151,1);
} */

/* design changes */
.container {
  margin-top: 7px !important;
  padding: 0px;
}
.navbar {
  /* background-image: linear-gradient(rgba(102,102,102,1), rgba(17,17,17,1)); */
  /* background: rgb(102,102,102);
  background: linear-gradient(153deg, rgba(102,102,102,1) 0%, rgba(17,17,17,1) 14%, rgba(17,17,17,1) 88%, rgba(102,102,102,1) 100%); */
  /* border-bottom-right-radius: 35px;
  border-bottom-left-radius: 35px; */
  color: white;
  height: 84px;
  background-color: #000000 !important;
  border-bottom: 0px;
  z-index: 999;
  /* background-image: url("assets/bgdnav.png") !important;
  background-size: cover !important; */
}
.hero h1, .shero h1 {
  /* position: absolute;
  top: 28px;
  right: 24%; */
  color: #232323;
  font-size: 26px;
  z-index: 999;
  font-weight: 700;
  padding-bottom: 0px;
  padding-top: 20px;
  margin-bottom: 0px !important;
  /* font-family: 'Figtree', sans-serif; */
  /* font-family: 'Playfair Display', sans-serif; */
  /* font-family: "Playfair Display", Sans-serif; */
  font-family: 'Bebas Neue', sans-serif;
  /* font-size: 50px; */

  color: #ebebeb;
  z-index: 9999;
  text-transform: uppercase;
  font-weight: 700;
  
  font-size: 50px;
  font-weight: 600;
  text-transform: uppercase;
  line-height: 44px;
  letter-spacing: 1px;
}
.padtopst {
  padding-top: 10px;
}
.padtoplg {
  padding-top: 30px;
}
.hero h1.four, .shero h1.four {
  text-align: left;
  margin-bottom: -12px !important;
  font-size: 24px;
  padding: 0px;
  text-transform: capitalize;
  padding-left: 125px;
}
.leader-item {
  color: #ffffff;
  box-shadow: none;
  border: 2px solid #ffffff;
  background-image:linear-gradient(#423785 , #a855bb);
  border-radius: 40px;
  /* margin: 3px 0px 10px; */
  margin: 3px 0px 0px;
  /* padding-left: 11px; */
  padding-left: 0px;
}
.smh {
  font-size: 20px;
  line-height: 19px;
  margin-bottom: -2px !important;
  color: #FC1;
  font-weight: 600;
  font-family: 'Bebas Neue', sans-serif;
  padding-top: 27px;
  letter-spacing: 10px;
  padding-left: 7px;
}
.leader-item .leader-rank {
  display: none;
}
.leader-item .leader-rank svg {
  z-index: 99;
}
.leader-item .point-holder {
  margin-right: 10px;
}
.active-leader-item {
  /* margin: 0px 10px 0px 10px; */
  /*height: 75px;*/
  display: flex;
  align-items: center;
  /* color: #2A413A; */
  color: rgb(255, 255, 255);
  background-color: transparent !important;
  /*background-image: linear-gradient(#423785 , #a855bb)  !important;*/
  position: relative;
  border: 1px solid #ffffff;
  border-radius: 40px;
  /* margin: 0px 20px 0px 23px; */
  margin: 0 3px 0 3px;
}
.active-leader-item-profile {
  /* background-image: linear-gradient(rgba(102,102,102,1), rgba(17,17,17,1), rgba(17,17,17,1), rgba(102,102,102,1)); */
  /* background-image: linear-gradient(#7a04eb, #7a04eb, #7a04eb, #120458); */
  /* background-image: linear-gradient(#0abdc6, #0abdc6, #0abdc6, #133e7c); */
  background: linear-gradient(0deg, #133e7c 0%, #0abdc6);
  margin: 10px 0 10px 20px;
  height: 94px;
  display: flex;
  align-items: center;
  color: white;
  box-shadow: 5px 3px rgb(0 0 0 / 30%);
}
.leader-item img {
  /* display: none; */
}
.leader-item .gravy {
  width: 40px;
  height: 40px;
  border: none;
  margin: -1px 8px 6px 0;
  
}
.active-leader-item .gravy {
  width: 50px;
  height: 50px;
  /* border: 3px solid yellow; */
  margin: 0 6px;
  margin-left: 9px;
}
.active-leader-item-profile .gravy {
  width: 68px;
  height: 68px;
  border: 3px solid yellow;
  margin: 0 18px;
}

.leader-rank {
  border-radius: 50%;
  width: 44px;
  height: 44px;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  /* font-family: 'Comfortaa', cursive; */
  font-size: 19px;
  font-weight: 700;
  display: block;
}
.active-leader-item  .leader-rank {
  display: block;
  position: absolute;
  color: black;
  margin-left: 0px;
  /*display: flex;*/
  justify-content: center;
  align-items: center;
  font-size: 13px;
  font-weight: 400;
  bottom: -30px;
  left: 0x;
}
.point-holder {
  color: #ffffff;
  transform: rotate(12deg); /* Equal to rotateZ(45deg) */
  display: flex;
  flex-direction: column;
  background-color:#222;
  border-radius:40px;
  margin-right:10px;
}
.point-holder .label {
  color: white;
  font-size: 14px;
  font-weight: 500;
}
.point-holder .value {
    font-size: 40px;
    font-weight: 600;
    line-height: 20px;
    /* font-family: 'Figtree', sans-serif; */
    font-family: 'Bebas Neue', sans-serif;
    color: white
  }
.active-leader-item-profile  .leader-rank {
  border: 1px solid #0abdc6;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  /* background-color: yellow; */
  color: black;
  /* z-index: 99999; */
  margin-left: -14px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'Figtree', sans-serif;
  /* font-family: 'Comfortaa', cursive; */
  font-size: 30px;
  font-weight: 700;
  background: rgb(246,246,210);
  background: radial-gradient(circle, #0abdc6 35%, #0abdc6 48%, #133e7c 64%);
}
iframe {
  display: none;
}
.leader-name {
  font-family:'Bebas Neue', sans-serif;
  /* font-size: 50px; */
  font-weight: 700;
  display: flex;
  flex: 1 1;
  flex-direction: column;
  text-align: left;
  padding-left: 3px;
  padding-bottom: 6px;
}
/* .leader-name::before {
  content: '-';
  margin-right: 7px;
  font-size: 20px;
} */
footer {
  min-height: initial;
}

.leader-level {
  font-size: 14px;
  font-weight: 400;
  padding-left: 0px;
  align-items: center;
  padding-left: 10px;
  margin-right: 20px;
  width: 100%;
}
.flex-level {
  flex: 1;
}
.active-leader-item .leader-name {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex: 1;
}
.active-leader-item-profile .leader-name {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex: 1;
}
.active-leader-item .leader-name::before {
  content: none;
}
.active-leader-item-profile .leader-name::before {
  content: none;
}
 /*
.active-leader-item .leader-level {
  padding-left: 0px;
}
.active-leader-item-profile .leader-level {
  padding-left: 0px;
}
*/

.purchase-container {
  display: flex;
  flex-direction: column;
  /* height: 78vh; */
  position: relative;
}
.porductinfod {
  /* flex: 1; */
}
.diachange {
  width: 100%;
  height: 100%;
}
.orderfill {
  flex: 1 1;
  padding-left: 10px;
  padding-top: 10px;
}
.button-holders {
  display: flex;
  width: 96%;
  padding-left: 10px;
  margin-top: 14px;
}
.button-holders .greygoose {
  margin-top: 0px !important;
  margin-right: 14px;
}
.button-holders .greygoose.nm {
  margin-top: 0px !important;
  margin-right: 14px;
  box-shadow: -3px 6px 7px #ffffff;
  color: #d3d3d3;
  background-color: #aaaaaa;
  font-weight: 700;
}
.zeropadtop {
  margin-top: 0px !important;
}
.padbad2 {
  padding-top: 18px;
}
.zeropadbot {
  padding-bottom: 0px!important;
  margin-bottom: 0px !important;
}
.checkouts {
  background-color: #52376f;
  /* box-shadow: -3px 6px 7px #bebebe; */
}
.lgwide {
  max-width: 600px;
    margin: 0 auto;
}
.grrre {
  background-color: #ffffff;
}
.cuseremail {
  font-style: italic;
}
.claimh {
  padding-left: 12px;
  text-shadow: 2px 2px 5px black;
  font-family: 'Bebas Neue', sans-serif;
    font-size: 45px;
    font-style: italic;
    padding: 12px 0;
}
.cuseremail {
  padding-left: 12px;
}
.pointse {
  color: #ffa200;
    font-size: 30px;
    font-weight: 500;
}
.content-note {
  font-size: 17px;
  padding-right: 13px;
}
.notes {
  border-top: 1px solid #666;
  margin: 0 20px;
  padding-top: 4px;
}
.userinfo {
  display: flex;
  flex-direction: column;
  padding: 16px 10px;
  /* font-size: 18px; */
  text-align: left;
  padding-left: 124px;
  padding-top: 1px;
  padding-bottom: 6px;
  font-size: 14px;
  color: #ffffff;
}
.topcont {
  display: flex;
  flex-direction: column;
}
.prodname {
  font-size: 17px;
  text-align: center;
  flex: 1 1;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  padding: 8px 0px 0 0;
  margin-bottom: 0px;
  font-weight: 400;
}
.porductinfod {
  display: flex;
  flex-wrap: wrap;
  padding-left: 7px;
}
.totals {
  font-size: 26px;
  padding-bottom: 5px;
  font-weight: 500;
  padding-top: 4px;
}
.inc {
  background: #52376f ;
  background: linear-gradient(270deg, #52376f 0%, #3c2852  100%);
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 15px;
  padding: 0 10px;
  text-align: center;
  cursor: pointer;
  border: 0px;
  flex: 1;
  height: 32px;
}
.inc.down {
  border-bottom-left-radius: 14px;
  border-bottom-right-radius: 0px;
  background: rgb(230,121,47);
  background: linear-gradient(90deg,  #52376f 0%, #3c2852 100%);
  margin-right: 4px;
}
.inc:hover, .inc:active, .inc:focus {
  background-color:#52376f;
}
.inc .mr-2, .inc .mx-2 {
  margin-right: 0!important;
}
.hawk {
  /* color: #fcca00;
  box-shadow: 0px 1px 7px #666; */
}
.product-i {
  width: 46%;
  display: flex;
  justify-content: center;
  height: 90px;
  align-items: center;
  border: 1px solid #666;
  margin: 7px 7px;
  /* cursor: pointer; */
}
.product-i.shmi {
  background-color: white;
  box-shadow: -3px 6px 7px #666;
  padding-bottom: 2px;
  padding-top: 0px;
  width: 47%;
  border: 1px solid #666;
  margin: 7px 4px;
  height: 150px;
  flex-direction: column;
}
.card-button-holders {
  display: flex;
  margin-bottom: 2px;
  width: 96%;
}
.container-lead:first-child .active-leader-item {
  background: rgb(207,178,23);
  background: linear-gradient(0deg, #ffc703 0%, #ff4200 100%);
  /* background: linear-gradient(0deg, hsla(45, 99%, 33%, 1) 0%, hsla(46, 61%, 52%, 1) 100%); */
}
.container-lead:nth-child(2)  .active-leader-item {
  background: linear-gradient(0deg, #10e4d6 0%, #06805b 100%);
  /* background: linear-gradient(360deg, rgba(111,107,103,1) 27%, rgba(77,75,74,1) 68%, rgba(188,185,182,1) 100%); */
  /* background: linear-gradient(0deg, rgb(192, 192, 192, 1) 0%, rgba(	179, 179, 179,1) 100%); */
}
.container-lead:nth-child(3)  .active-leader-item {
  background: rgb(205,127,50);
  background: linear-gradient(0deg, #00c0ef 0%, #1064ff 100%);
/* background: linear-gradient(360deg, rgba(205,127,50,1) 54%, rgba(205,171,138,1) 100%); */
}
.container-lead:nth-child(even) .active-leader-item-profile {
  background: #091833;
  /* background: linear-gradient(0deg, #133e7c 0%, #0abdc6); */
  background: linear-gradient(0deg, #091833 0%, #133e7c);
  
}
.leader-suffix {
  font-size: 12px;
  margin-top: -8px;
}
.active-leader-item .leader-suffix {
  font-size: 17px;
  margin-top: -8px;
}
.active-leader-item-profile .leader-suffix {
  font-size: 17px;
  margin-top: -8px;
}
.navbar-toggler {
  margin-right: 12px !important;
}
.container {
  margin-top: 0px !important;
}
.navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255,255,255, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E") !important;
}

.navbar-toggler {
  border: none;
} 
.navbar-toggler-icon {
  display: inline-block;
  width: 30px;
  height: 30px;
}
.svg-inline--fa.fa-w-12 {
  width: 43px;
  margin-right: 12px;
  height: 38px;
}

/* profile changes */
.leader-h1 {
  position: absolute;
  top: 28px;
  right: 37%;
  color: #d4cac8;
  font-size: 22px;
  z-index: 9999;
  text-transform: uppercase;
  font-weight: 700;
}
.reward-item {
  /* background: linear-gradient(0deg, rgba(205,127,50, 1) 0%, rgba(205,141,110, 1) 100%) !important; */
}
.navbar-nav {
  /* background-color: #111; */
  z-index: 99;
  /* margin-top: 0px !important; */
  color: white;
  margin-left: 0px;
  padding-left: 24px;
  padding-right: 24px;
  margin-top: 9px !important;
}
.rank-num {
  position: absolute;
  left: 2px;
  background-color: rgb(255, 234, 0);
  width: 20px;
  height: 20px;
  top: 1px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0px 4px 0px 0px;
  border-radius:30px;
  font-weight:700;
  font-size:20px;
}
.modslvl {
  padding-top: 0px;
  color: #ffcc00;
  padding-bottom: 8px;
  font-weight: bold;
}
.stee {
  width: 26px !important;
  margin-right: 12px !important;
  height: 26px !important;
  z-index: 9;
}
.widthsev {
  width: 70% !important;
}
.font-adj {
  font-size: 32px !important;
  color: white;
}
.paragraph-6 {
  color: white;
}
.overbu {
  color: white
}
.navbar>.container {
  position: absolute;
  top: 20px;
}
.navbar>.container, .navbar>.container-fluid {
  z-index: 99;
}
.leadery .leader-level {
  width: auto;
}
.backersty .refix {
  color: white;
}
.memsince {
  position: initial !important;
  flex: 1;
}
.lastdance {
  flex: 1;
  position: initial !important;
}
.yellink {
  color:#fc0;
}
.bigbio {
  font-size: 14px;
  padding-top: 8px;
}
.searchfield {
  border-top-left-radius: 10px;
  border-bottom-right-radius: 10px;
  border-top-right-radius: 10px;
  border-bottom-left-radius: 10px;
  padding: 8px 20px;
  width: 80%;
  margin: 2px 0 8px 0;
}
.claimpart {
  color: #0e74b8;
  padding-top: 16px;
  cursor: pointer;
}
.defix {
  width: 90% !important;
  height: 85vh;
  top: 7% !important;
  overflow: auto;
  /* background-color: lightyellow !important; */
}
.subster {
  font-size: 15px;
}
.gravhold {
  flex-direction: column;
}
.gravhold .final-ava {
  border: 3px solid #9f5965 !important;
}
.newbio {
  font-size: 18px;
  line-height: 24px;
  padding: 14px 0;
  /* color: #fc0; */
}
.dclaimg {
  font-size: 60px;
  color: #ffffff;
  text-transform: uppercase;
}
.fartnerimage {
  width: auto;
  display: initial !important;
  margin: 0 auto;
  padding-right: 10px;
  padding-bottom: 12px;
  max-height: 280px;
}
.fpitem {
  height: inherit!important;
  margin: 14px 0;
  padding-bottom: 13px;
  padding-right: 4px;
  padding-top: 12px;
  padding-left: 4px;
}
.pjlinks {
  font-size: 18px; 
  flex: 1;
  white-space: nowrap;
}
.partnerimage {
  display: initial !important;
  height: 32px;
  padding-right: 10px;
}
.ve {
  width: 20px !important;
  margin-right: 0px !important;
  height: 20px !important;
}
.morestuff::before {
  content: none !important;
}
.morestuff .stuff {
  text-transform: uppercase;
  font-size: 33px;
  display: flex;
  align-items: center;
}
.levler {
  margin: 0px !important;
  padding: 0px !important;
}
.rizzow {
  flex-direction: row;
  align-items: center;
  width: 100%;
  margin-bottom: 12px;
  display: flex;
  justify-content: center;
}
.flexholder {
  margin-bottom: 13px;
}
.boat {
  margin-top: 4px;
  width: 50px;
  height: 50px;
  margin-bottom: 4px;
}
.starty .profile-picture {
  position: relative;
  top: auto;
  border-radius: 50% !important;
  border: 3px solid #fff;
  left: auto;
  margin-bottom: 0px !important;
  height: 104px;
  width: 104px;
}
.final-ava {
  border-radius: 50%;
  width: 200px;
  height: 200px;
  border: 3px solid #fff;
  margin: 0 6px;
  margin-left: 0px;
  margin-right: 0px;
}
.user-inf-new .edit-button {
  color: #1bbf25;
  font-weight: 600;
  cursor: pointer;
  font-size: 18px;
  padding-top: 0px;
}
.newbadge {
  border: 3px solid #9f5965;
  padding: 0px;
  width: 104px;
  height: 104px;
  /* margin: 0 10px; */
  margin: 0 4px;
}
.newbig {
  /* border: 3px solid #9f5965;
  padding: 0px;
  width: 124px;
  height: 124px; */

  border: 4px solid #ffffff;
  padding: 0px;
  width: 200px;
  height: 200px;

}
.clicks {
  background-color: darkgreen;
  color: white;
  cursor: pointer;
}
.walleteth {
  padding: 14px 0px;
}
.whitewallet {
  color: white;
  padding-bottom: 1px;
  margin-bottom: 1px;
}
.qrlink {
  color: white
}
.darkwallet {
  color: #111;
  padding-bottom: 1px;
  margin-bottom: 1px;
}
.flutarsky button:disabled, .flutarsky button[disabled]{
  opacity: .4;
  cursor: not-allowed;
}
.seper {
  padding: 0 8px
}
.fonsmal {
  font-size: 14px;
}
.linksholder {
  display: flex;
  padding-top: 10px;
  flex-wrap: wrap;
}

.relink {
  color: #ffffff;
}
.holder-label {
  color: white;
  font-size: 18px;
  padding-bottom: 4px;
}
.openseabdg.green {
  cursor: initial;
}
.pad-bott {
  padding-bottom: 14px;
}
@media (min-width: 768px){
  .nav-item a {
    color: #fff !important;
}
.navbar-expand-md .navbar-nav {
    -ms-flex-direction: row;
    flex-direction: row;
    margin-top: 0px !important;
    padding-top: 0px;
    background: transparent;
    flex-direction: row;
    margin-top: 0 !important;
    padding-top: 0;
    background: transparent;
    text-align: right;
    justify-content: flex-end; /* Aligns items to the right */
    /* width: 100%; Ensures it spans the full width */
}
.mr-3, .mx-3 {
  margin-right: 4px !important;
}
.navbar-expand-md .navbar-nav .nav-item {
  padding-right: 14px;
  font-size: 14px;
  display: flex;
  justify-content: right;

}
}
.scanclick {
  font-size: 24px;
  padding-bottom: 24px;
}
.scanclick:hover {
  color: lightblue

}
.onemore {
  margin-bottom: 42px !important;
}
.scanclick .editbut {
  margin: 0 12px
}
.avatar-selector {
  display: flex;
  padding-top: 8px;
}
.avatar-image-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  flex: .7;
}
.avatar-image-container img {
  margin-bottom: 0px !important;
  height: 142px;
  width: auto;
  border-radius: 50%;
  border: 3px solid #666;
  border-style: solid;
  margin-right: 2px;
}
.form-group {
  margin-bottom: 1rem;
  text-align: left;
}
.avatar-list {
  display: flex;
  flex-wrap: wrap;
  flex: 1 1;
  z-index: 1234;
  justify-content: center;
}
.aavatar-list .form-check {
  position: relative;
  padding-left: 0;
  display: flex;
  align-items: center;
  width: 80px;
  justify-content: center;
}
.avatar-list .form-check-label{
  display: flex;
  flex-direction: column;
  margin-bottom: 14px;
  align-items: center;
  justify-content: center;
  font-size: 12px;
}
.avatar-list .final-ava {
  width: 57px;
  height: 57px;
  border: 1px solid yellow;
}
.avatar-list  .form-check-input {
  position: relative;
  margin: auto;
  margin-bottom: 6px;
}
.avatartitle {
  padding-top: 10px;
  /* font-family: 'Black Ops One', cursive !important; */
  font-size: 22px;
  font-family: 'Comfortaa', cursive !important;
  font-weight: bold;
}
.small {
  height: 70px !important;
  width: 70px !important;
  border: 2px solid #fff !important;
}
.scanlogoholder {
  display: flex;
  flex-direction: column;
}
.scanlogoholder .sublogo {
  font-size: 14px;
  letter-spacing: .5px;
  color: #fff !important;

}
.scanlogoholder .bblogo {
  font-size: 30px;
  padding-top: 10px;
  color: #fff !important;
}
.nfdbanner {
  width: 95px;
  padding-top: 16px;
  padding-bottom: 0px;
  margin-bottom: 0px;
}
.daddybutton {
  font-family: 'Luckiest Guy', cursive;
  -webkit-border-radius: 28;
  -moz-border-radius: 28;
  border-radius: 28px;
  text-shadow: 1px 1px 4px #000000;
  color: #ffffff;
  font-size: 20px;
  padding: 8px 20px 8px 20px;
  text-decoration: none;
  display: flex;
  width: 100%;
}
.nopadside {
  padding-left: 0px !important;
  padding-right: 0px !important;
}
.daddybutton:hover {
  text-decoration: none;
  text-shadow: none;
}
.blue-btn {
  background: #52376f;
  background-image: -webkit-linear-gradient(top, #52376f, #5a3c7a);
  background-image: -moz-linear-gradient(top, #52376f, #5a3c7a);
  background-image: -ms-linear-gradient(top, #52376f, #5a3c7a);
  background-image: -o-linear-gradient(top, #52376f, #5a3c7a);
  background-image: linear-gradient(to bottom, #52376f, #5a3c7a);
}
.yellow-btn {
  background: #ffc832;
  background-image: -webkit-linear-gradient(top, #f87524, #ffc832);
  background-image: -moz-linear-gradient(top, #f87524, #ffc832);
  background-image: -ms-linear-gradient(top, #f87524, #ffc832);
  background-image: -o-linear-gradient(top, #f87524, #ffc832);
  background-image: linear-gradient(to bottom, #f87524, #ffc832);
}
.blue-btn:hover {
  background: #496a62;
  background-image: -webkit-linear-gradient(top, #5a3c7a, #52376f);
  background-image: -moz-linear-gradient(top, #5a3c7a, #52376f);
  background-image: -ms-linear-gradient(top, #5a3c7a, #52376f);
  background-image: -o-linear-gradient(top, #5a3c7a, #52376f);
  background-image: linear-gradient(to bottom, #5a3c7a, #52376f);
}
.yellow-btn:hover {
  background: #ffc832;
  background-image: -webkit-linear-gradient(top, #f0bea0, #ffe6a6);
  background-image: -moz-linear-gradient(top, #f0bea0, #ffe6a6);
  background-image: -ms-linear-gradient(top, #f0bea0, #ffe6a6);
  background-image: -o-linear-gradient(top, #f0bea0, #ffe6a6);
  background-image: linear-gradient(to bottom, #f0bea0, #ffe6a6);
}
#minterbtn {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 5px;
}
.text-btn-ctn {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  margin-left: 8px;
}
.btn-icon {
  font-size: 56px;
  opacity: .4;
}
.but-lab {
  font-size: 34px;
  text-transform: uppercase;
  font-weight: bold;
  padding: 0px;
  line-height: 38px;
  margin: 0px;
  padding-top: 3px;
  letter-spacing: 3px;
  text-align: left;
}
.sublabel-btn {
  font-family: 'Comfortaa', cursive;
  text-transform: uppercase;
  font-size: 15px;
  margin-top: -5px;
  text-align: left;
}
a:link {
  text-decoration: none;
}

a:visited {
  text-decoration: none;
}

a:hover {
  text-decoration: none;
  color:hotpink;
}

a:active {
  text-decoration: none;
}
.konegg {
  background-color: #7a9a86;
  background-image: none;
}
.konegg .closebtn {
  display: none;
}
.eggbgd {
  background-image: url("assets/konami-easter.png") !important;
  background-size: cover !important;
  height: 322px;
}
.egg-header {
  font-family: 'VT323', monospace;
  letter-spacing: -1px;
  text-transform: uppercase;
}
.sub-egg-header {
  font-family: 'VT323', monospace;
  font-size: 30px;
  line-height: 30px;
  margin: 0 12px;
}
.eggclosebtn {
  margin: 0px !important;
  font-size: 18px;
  padding: 0px !important;
  width: 11px;
}
.eggclose {
  font-family: 'VT323', monospace;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 20px;
  padding-top: 10px;
  color: #e8e3ec;
  cursor: pointer;
}
.avatar-saver {
  display: flex;
}
.admin-title {
  padding-top: 40px;
}
.newerbadge {
  width: 80px;
  height: 80px;
}
.navbar>.container {
  position: relative;
  top: 0;
}
.modal-animation {
  transition: transform 1s ease-in-out;
  transform: translateX(100%);
}
.bwu-contatiner {
  padding-left: 8px;
}
.bwu {
  width: 110px !important;
}
.open {
  transform: translateX(-50%);
}
.removeuser {
  position: absolute;
  right: 25px;
  top: 15px;
  color: #fff;
  font-weight: bold;
  cursor: pointer;
}
.bonus-reward {
  display: flex;
  color: white;
  flex: 1;
}
.bonusholder {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  margin-top: 13px;
}
.full-width {
  width: 100%;
  padding-top: 23px;
  text-align: left;
}
.bonusholder div{
  flex: 1;
  text-align: center;
}
.bonusholder .mr-3, .bonusholder .mx-3 {
  margin-right: 0px !important;
}
.highz {
  z-index: 9999;
}
.paddybot {
  margin-bottom: 30px !important;
}
.rightbtnpad {
  padding-right: 30px;
}
.col-card svg, .pointer {
  cursor: pointer;
}
.bonusholder button:nth-child(odd) {
  background-color: lightgrey;
  color: black;
}
.col-card {
  flex-direction: column;
    padding: 15px 0;
}
.bonusholder button:nth-child(even) {
  background-color: white;
  color: black;
}
.newsubtitle {
  margin-top: 26px;
  color: #111;
  margin-bottom: 0px;
  padding-bottom: 0px;
}
.bonusholder button .bonus-reward{
  color: black;
  min-height: 39px;
  display: flex;
  align-items: center;
}
.bonusholder  .linker {
  color: white
}
.bodesign {
  border: 3px solid white;
    padding: 0 8px !important;
}
.table-title-th {
  font-size: 18px;
}
.buttond {
  margin-top: 28px;
}
.twe {    margin-left: 0px;}
.genname {
  font-size: 33px;
  padding-top: 0px;
  margin-top: 0px;
  line-height: 28px;
  margin-bottom: 16px;
}
.genbut {
  margin-bottom: 24px;
  font-weight: bold;
  font-size: 16px;
  padding: 3px 13px;
}
.refix .active-j {
  border: 6px solid blue
}
.newheader {
  text-transform: uppercase;
  font-weight: 600;
  font-family: 'Roboto', sans-serif;
  font-size: 28px;
  width: 240px;
}
.bhdLno {
  position: relative;
  width: 96vw !important;
  height: 96vw !important;
}
.text-points {
  font-style: italic;
    color: #52376f !important;
    font-weight: bold;
}

.admin-tabs .nav-item a {
  color: #fff !important;
  font-size: 15px;
  font-weight: 500;

}
.admin-tabs .nav-item .active {
  color: #52376f !important;
  font-weight: 700;
}
.admin-tabs {
  margin-top: 26px;
  margin-bottom: 30px;
}
.form-control {
  width: 100% !important;
}
.bonusholder div {
  color: #111;
  align-items: center;
}
.bonus-reward:nth-child(even){
  background-color: lightgrey;
  color: black;
}
.bonus-reward svg, .nav-link {
  cursor: pointer;
}
.reward-card {
  border: 1px solid #111;
  background-color:#fff;
  border-radius: 40px;
  padding: 15px 20px;
  height: 100%;
}
.rewardholder {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  margin-top: 13px;
  padding: 0 30px;
}
.bwu button {
  background: transparent;
  margin: 0 0 8px 0;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    padding-bottom: 0px;
    margin-bottom: 0px;
}
.badge-container {
  margin-top: 16px
}
.active-leader-item .point-holder, .container-lead .point-holder {
  color: #111 !important
}
.container-lead button {
  padding: 2px 0px !important;
}
.header-pcl {
  text-align: left;
  font-size: 20px;
  padding-top: 20px;
  text-transform: uppercase;
}
.adminlink {
  color: #1bbf25  !important;
}
.rewardclaimtext {
  font-size: 14px;
}
.rewardclaimtext .subtitle-change {
  text-align: center;
    font-size: 20px;
    color: purple;
    font-size: 14px;
    font-size: bold;
}
.topstuff.ddog button {
  margin-top: 0px;
}

.redeemimg {
  height: 99px;
  float: right;
}
.uplg {
  font-family: "Bebas Neue", sans-serif;
  font-size: 18px !important;
  letter-spacing: 10px !important;
  margin-top: 0px;
  padding-top: 0px;

}
.uplh {
  margin-top: 34px;
  font-family: "Bebas Neue", sans-serif;
  font-size: 41px !important;
  margin-bottom: 0px;
  padding-bottom: 0px;
  line-height: 36px;
}
.new-header {
  font-size: 50px;
  font-weight: 600;
  text-transform: uppercase;
  line-height: 44px;
  letter-spacing: 1px;
  font-family: 'Bebas Neue', sans-serif;
  color: white;
  margin-bottom: 0px !important;
  text-align: center;
  margin-top: 20px;
}
.updatedh {
  letter-spacing: inherit;
  margin-bottom: 10px !important;
  line-height: 24px;
}
.newyellow {
  color: #fc0 !important;
}
.h-1000 {
  height: 100vh !important;
}
#root {
  height: 100% !important;
}
.hnit {
  height: initial !important;
}
.form-check {
  padding-left: 0px;
}
.item-a {
  padding-left: 1.25rem;
}
.item-a:first-child {
  padding-left: 0;
}

.backstyle {
  pointer-events: none; /* Prevent interaction with backdrop */
}

.refix {
  pointer-events: auto; /* Ensure modal is interactive */
}